import baseComponent from '@/scripts/baseComponent'
import { mapState } from 'vuex'
import { API_CALL_STATUS } from '@/constant/config'
import draggable from 'vuedraggable'
export default {
    extends: baseComponent,
    components: {
        draggable
    },
    data() {
        return {
            listConditionsAvailable: [
                { id: 16, name: 'Total deposited', parameters: [{ type: 'number', min: 0 }] },
                { id: 17, name: 'Is Prime', parameters: [{ type: 'checkbox', label: 'Is Prime user or not' }] },
                { id: 20, name: 'Register date', parameters: [{ type: 'datecompare' }]}
            ],
            listConditionsSet: [
                [{ id: 18 }],
                [{ id: 19 }]
            ],
            isLoading: false,
            drag: false,
            editItem: null,
            isModified: false,
            dragingCondtionalData:  {
                item: null,
                oldIndex: -1,
                listSource: null,
                indexOfList: null,
            },
        }
    },
    computed: {
        isShowAddButton(){
            return this.listConditionsSet.length <= 1;
        },
        dragOptions() {
            return {
                animation: 200,
                group: 'description',
                disabled: false,
                ghostClass: 'ghost'
            }
        },
        ...mapState({
            userInfo: (state) => state.auth.user,
            rolesInfo: (state) => state.auth.roles,
            listStaff: (state) => state.staffs,
            decentralization: (state) => state.auth.decentralization
        })
    },
    mounted() {},
    beforeDestroy() {},
    methods: {
        onDropCondSet() { 
            this.listConditionsSet.push([{ ... this.dragingCondtionalData.item }])
            if(this.dragingCondtionalData.listSource) this.dragingCondtionalData.listSource.splice(this.dragingCondtionalData.oldIndex, 1)
            this.listConditionsSet = this.listConditionsSet.filter(xChild => xChild.length > 0)     
            this.dragingCondtionalData = {
                item: null,
                oldIndex: -1,
                listSource: null,
                indexOfList: null,
            };
        }, 
        onDropCondSetCurrentList(listSource) { 
            if(this.dragingCondtionalData.item.id){
                const foundIndex = listSource.findIndex(x => x.id == this.dragingCondtionalData.item.id)
                if(foundIndex < 0) {
                    listSource.push({ ... this.dragingCondtionalData.item })
                    if(this.dragingCondtionalData.listSource) {
                        this.dragingCondtionalData.listSource.splice(this.dragingCondtionalData.oldIndex, 1)
                    }
                }
            }
            this.listConditionsSet = this.listConditionsSet.filter(xChild => xChild.length > 0)    
            this.dragingCondtionalData = {
                item: null,
                oldIndex: -1,
                listSource: null,
                indexOfList: null,
            };
        }, 
        dragStart(evt, listSource, indexOfList, isCondAvailable = false) {
            this.drag = true 
            let item = listSource[evt.oldDraggableIndex] 
            this.dragingCondtionalData = {
                item,
                oldIndex: evt.oldDraggableIndex,
                listSource: isCondAvailable ? null : listSource,
                indexOfList
            }
        }, 
        dragChanged() {
            this.drag = false
            this.listConditionsSet = this.listConditionsSet.filter(xChild => xChild.length > 0)
        },
        cloneItem({ id }){
            const foundItem = this.listConditionsAvailable.find(x => x.id == id)
            return { ...foundItem }
        }, 
        addCond(condItem){
            if(this.listConditionsSet.length === 0){
                this.listConditionsSet = [
                    [{...condItem}]
                ]
            }
            if(this.listConditionsSet.length === 1){                
                const foundIndex = this.listConditionsSet[0].findIndex(x => x.id == condItem.id)
                if(foundIndex < 0) this.listConditionsSet[0].push({...condItem})
            }
            this.listConditionsSet = this.listConditionsSet.filter(xChild => xChild.length > 0)
        },
        deleteCond(listSource, indexItem){
            listSource.splice(indexItem, 1)
            this.listConditionsSet = this.listConditionsSet.filter(xChild => xChild.length > 0)
        },
        dropdownItemAction(params) {
            switch (params.action) {
                case 'edit':
                    this.editHandle(params.item)
                    break
                case 'delete':
                    this.deleteHandle(params.item)
                    break
            }
        }
    }
}
